
const Arrow = () => {
    return (
        <div className="text-center container-icon ir-arriba">
            <a href="#header" style={{ display: "inline-block", padding: "5px", borderRadius: "50%" }}>
                <img src="icons/arrow.png" width={"60px"} alt="Go to Top" />
            </a>
        </div>
    );
};
export default Arrow;
